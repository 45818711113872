import React, { useEffect } from "react";
import { navigate } from "gatsby-link";

const Pabbly = () => {
  useEffect(() => {
    navigate('/pabbly-connect/');
  }, []);

  return (
    <></>
  );
};

export default Pabbly;
